.App {
  text-align: center;
}

.contenitore-generale {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.input-container {
  flex: 1 0 calc(25% - 20px); /* Larghezza minima del 25%, con margine di 10px su entrambi i lati */
  max-width: calc(25% - 20px);
  margin: 10px;
  box-sizing: border-box;
  min-width: 400px;
}

.input-box {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

